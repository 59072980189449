import React, { useContext, useEffect, useState } from 'react'
import rightArrow from "../assets/images/right-arrow.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import allGames from "../assets/images/all-games.svg";
import { gameTypebyClientId } from '../constants/axios';
import url from '../constants/url';
import Close from "../assets/images/close.svg";
import Logo from '../assets/images/Gamerji_Text_logo.png'
import PlayerIcon from '../assets/images/Player-icon.png'
import BGMI from '../assets/images/bgmi.png'
import ChangeUsernamePopup from './ChangeUsernamePopup';
import ConfirmationPopUp from './ConfirmationPopUp';
import { useTheme } from '../ThemeContext/ThemeContext';
import Base from './Base';
import CustomLoader from '../CommonComponent/CustomLoader';
import NotAuthorizedUser from './NotAuthorizedUser';
import Loader from './Loader';
import Background from '../assets/images/background.jpg'
import PlayStore from '../assets/images/playstore.png'
import Google from '../assets/images/google.png'
import clevertap from "clevertap-web-sdk";
import { useTranslation } from "react-i18next";

const GameType = () => {
  const [data, setData] = useState([]);
  const [newName, setnewtName] = useState('');
  const [showContacts, setShowContacts] = useState(false)
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation();

  // console.log("value>>>>", useTheme())
  const globalvalues = useTheme()

  const cancelUserName = () => {
    setShowContacts(false)
  }

  useEffect(() => {

    // fetchLoginToken()
    clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
    clevertap.init(url.cleverTabDetails.cleverTapAccount, "in"); //
    clevertap.setLogLevel(3); 
    fetchGameType()
  }, [])


  const fetchGameType = async () => {
    setLoader(true)
    let json_object = {"action": "event" ,"eventName":"Gamerji_Visited", "event_properties":{
      "PageName": "Game-Type",
      "ClientId":globalvalues?.clientId,
      "Date": new Date()
    }}
   let data = JSON.stringify(json_object);
    window.parent.postMessage(data, "*");


    let body = {
      game: globalvalues?.gameCode,//"634a42a89633f3f5b7228455",
      clientId: globalvalues?.clientId,//"64bbc4e4f94a2cb38047d389"
    }
    try {

      const res = await gameTypebyClientId(body)
      // console.log(res?.data)
      if (res?.data?.success) {
        setData(res?.data?.data)
      }
      setLoader(false)
    }
    catch (error) {
      console.log(error)
      let data = JSON.stringify({ "action": "back" });
      window.parent.postMessage(data, "*");

    }
  }
  const imageHandler = (id, img, name) => {
    navigate(`/contest/${id}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`, { state: { img: img, name: name } })
  }
  if (loader) return <Loader /> //<h2 style={{color:'white'}}>Loading..............</h2>
  if (!globalvalues?.isLoggedIn) return <NotAuthorizedUser />
  const button1Handler = () => {
    navigate(`/my-contest?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`)
    // window.location.href = `/my-contest?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`
  }

  return (
    <Base title={data?.game}>
      <h6 className='choose-text'>{t("gameType.choose_a_game_type")}</h6>
      <div className="games_items">
        {/* {console.log(data)} */}
        {data?.gameType?.map((item, index) => {
          return (
            <>
              <div className="item" key={index} onClick={() => imageHandler(item._id, item?.displayImage?.default, data?.gameType[index]?.name)}>
                {/* // eslint-disable-next-line */}
                {/* <Link to={`/contest/${item._id}?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`} className="d-block"> */}


                <div className="img">
                  <img
                    src={`${url.imageUrl}${item?.displayImage?.default}`}
                    // src={BGMI}
                    alt="" />

                </div>
                <div className='box-text'>
                  <h3 >{item?.name?.toLowerCase()}</h3>
                  <div className='playerIcon'><img src={PlayerIcon} /><h3> {t("gameType.64k")}</h3></div>
                </div>

                {/* </Link> */}
              </div>


            </>
          );
        })}


      </div>
      <p style={{fontSize:'14px',textAlign:'center', color:"var(--headerText)"}}> {t("gameType.kindly_download_the_game_on_your_mobile_device_and_join_the_game_once_we_share_the_room_id_and_passcode_with_you")}
</p>
      <div style={{display:'flex', justifyContent:"center", alignItems:'center', margin:'20px 0'}}>
                <div className='playstore' style={{marginRight:'20px'}} onClick={()=>{window.open(data.downloadLinkIos,"_blank")}}>
                    <img src={Google} />
                    </div>
                <div className='playstore'  onClick={()=>{window.open(data.downloadLink,"_blank")}}>

                    <img  src={PlayStore}/>
                    </div>
                    </div>
      <div className="changeUserName"
        style={{ width: '100%', }} >
{t("gameType.want_to_add_change_user_in_game_name", {gamename:data.game?.toLowerCase()})} <br />
        <b className="redClick" onClick={() => setShowContacts(true)}>
        {t("gameType.click_here")}
        </b>

      </div>
      
      <div className='bottom_btn'>
        <div className='bottom-button'>
        <div className='footer-btn button2'>
            <Link to={`/how-to join?clientId=${globalvalues?.clientId}&gameCode=${globalvalues?.gameCode}&authCode=${globalvalues.authCode}`} style={{color:"var(--header-text)"}}>
            {t("gameType.how_to_join")}

              </Link>
          </div>
          <div className='footer-btn  button1' onClick={button1Handler}>

          {t("gameType.my_contests")}
            {/* <img src={rightArrow} alt="" className="mCS_img_loaded" /> */}

          </div>
          
        </div>
        {/* <div className='footer-logo'>
            <h2>Hosted by: <br />
                    </h2>
                  <img src={Logo} />
                  
            </div> */}
             
      </div>
      {showContacts === true && (
        <ChangeUsernamePopup cancelUserName={cancelUserName} gameName={data.game} inNameGame={globalvalues?.inNameGame} gameCode={globalvalues?.gameCode} userId={globalvalues?.userId} changeInNameGame={globalvalues?.changeInNameGame} />


      )}

    </Base>
  )
}

export default GameType